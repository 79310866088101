import React from 'react'

const Adsense = () => {
  return (
    <ins
      className="adsbygoogle"
      style={{ display: "inline-block", width: "728px", height: "90px" }}
      data-ad-client="ca-pub-4210779179353314"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
}

export default Adsense